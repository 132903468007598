<template>
  <div class="toggle-switch d-flex justify-space-between align-center">
    <div
      v-for="(option, index) in options"
      :key="option"
      class="toggle-switch__option"
      :class="{
        'toggle-switch__option_selected elevation-2': index === selectedIndex,
      }"
      @click="onSelectOption(index)"
    >
      {{ option }}
    </div>
  </div>
</template>

<script lang="ts" setup>
withDefaults(
  defineProps<{
    options: string[]
    selectedIndex: number
  }>(),
  { selectedIndex: 0 }
)

const emit = defineEmits<{ (event: 'change', state: number) }>()

const onSelectOption = (index: number) => {
  emit('change', index)
}
</script>

<style lang="sass" scoped>
$ns: toggle-switch

.#{$ns}
  background: $c-grey-fine
  border-radius: 2rem
  padding: 0.25rem

  &__option
    padding: 0.6rem 0.5rem
    background: transparent
    border-radius: 3rem
    color: $c-grey-dark
    flex: 1 0 auto
    font-weight: 600
    text-align: center

    &:hover
      color: $c-primary
      cursor: pointer

    &_selected
      background: $c-white
      color: $c-skribbleu

      &:hover
        color: $c-skribbleu
        cursor: default
</style>
